import React, { PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { useAppDispatch } from '../store/hooks';
import { logout } from '../store/authSlice';
import Sidebar from '../components/Sidebar';
import { Button } from '../components/Button';

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  background-color: #2e2f38;
  color: #d1d5db;
  height: 3rem;
`

const Content = styled.div`
  display: flex;
  height: calc(100vh - 3rem);
`


const Home: React.FC<PropsWithChildren> = () => {
  const dispatch = useAppDispatch();

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    dispatch(logout());
  };

  return (
    <div>
      <Header>
        <h2>Chat24</h2>
        <Button onClick={handleLogout}>Logout</Button>
      </Header>
      <Content>
        <Sidebar />
        <Outlet/>
      </Content>
    </div>
  );
};

export default Home;