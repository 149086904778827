import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store/store';
import { sendMessage, postMessage, Message, getAssistants } from '../store/chatSlice';
import { Spinner } from './Spinner';
import { useAppSelector } from '../store/hooks';
import AssistantDropdown from './DropDown';

const StyledContainer = styled.div`
  display: flex;
  align-items: flex-end;
  padding: .7rem;
  background-color: #2d2d2d;
`;

const StyledInputContainer = styled.div`
  display: flex;
  flex: 1;
  position: relative;
`;

const StyledTextarea = styled.textarea`
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #555;
  border-radius: 0.5rem;
  background-color: #40414f;
  color: #d1d5db;
  box-sizing: border-box;
  resize: none; /* Prevent manual resizing by the user */
  overflow: hidden; /* Hide scrollbar */
`;

const StyledSpinner = styled(Spinner)`
  position: absolute;
`;

export const ChatInput: React.FC = () => {
  const [input, setInput] = useState('');
  const [selectedAssistant, setSelectedAssistant] = useState<string>('');
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const dispatch = useDispatch<AppDispatch>();
  const { loading } = useAppSelector((state) => state.chat);

  const sendMessageHandler = async () => {
    const trimmedInput = input.trim();
    if (trimmedInput) {
      const userMessage: Message = {
        role: 'user',
        content: trimmedInput,
        assistant: selectedAssistant
      };

      // Dispatch sendMessage to add user message to state
      dispatch(sendMessage(userMessage));
      setInput('');

      // Dispatch postMessage to send the message to the API
      try {
        await dispatch(postMessage(userMessage));
        textareaRef.current?.focus();
      } catch (error) {
        console.error('Failed to send message:', error);
      }
    }
  };

  const handleAssistantSelect = (assistantId: string) => {
    setSelectedAssistant(assistantId)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      sendMessageHandler();
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInput(event.target.value);
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [input]);

  useEffect(() => {
    dispatch(getAssistants())
  }, [dispatch])

  return (
    <StyledContainer>
      <AssistantDropdown onSelect={handleAssistantSelect} />
      <StyledInputContainer>
      <StyledTextarea
        ref={textareaRef}
        value={input}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder={!loading ? 'Type your message and press Enter...' : undefined}
        rows={1} // Start with one row
        disabled={loading}
      />
      {loading && <StyledSpinner/>}
      </StyledInputContainer>
    </StyledContainer>
  );
};