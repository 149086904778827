import React, { useState, ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { Source } from '../store/chatSlice';
import styled from 'styled-components';
import { useEffect } from 'react';

interface AssistantDropdownProps {
  onSelect: (assistantId: string) => void;
}

const DropdownContainer = styled.div`
  margin-right: 10px;
`;

const Select = styled.select`
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #555;
    border-radius: 0.5rem;
    background-color: #40414f;
    color: #d1d5db;
    box-sizing: border-box;
    resize: none; /* Prevent manual resizing by the user */
    overflow: hidden; /* Hide scrollbar */
`;

const Option = styled.option`
  padding: 10px;
  font-size: 16px;
`;

const AssistantDropdown: React.FC<AssistantDropdownProps> = ({ onSelect }) => {
  const assistants = useSelector((state: RootState) => state.chat.assistants);
  const [selectedAssistant, setSelectedAssistant] = useState<string>('');

  const handleSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    const assistantId = event.target.value;
    setSelectedAssistant(assistantId);
    onSelect(assistantId);
  };
  
  useEffect(() => {
    if (assistants.length > 0) {
      setSelectedAssistant(assistants[0].id); // Auto-select the first option
    }
  }, [assistants]);
  return (
    <DropdownContainer>
      <Select
        id="assistant-select"
        value={selectedAssistant}
        onChange={handleSelect}
        required
      >
        <Option value="" disabled>Select assistant</Option>
        {assistants.map((assistant: Source) => (
          <Option key={assistant.id} value={assistant.id}>
            {assistant.name}
          </Option>
        ))}
      </Select>
    </DropdownContainer>
  );
};

export default AssistantDropdown;