// src/components/PrivateRoute.tsx
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from '../store/hooks'; // Ensure this import

interface PrivateRouteProps {
  children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated); // Use Redux selector
  const hasToken = localStorage.getItem('access_token');
  const location = useLocation();

  console.log('PrivateRoute isAuthenticated:', isAuthenticated, 'current location:', location.pathname);
  // Temporary solution, need to fix authentication handling so that it is kept in store
  if (!hasToken) {
    console.log('User not authenticated, redirecting to /login');
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

export default PrivateRoute;