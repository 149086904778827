// src/pages/LoginPage.tsx
import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { loginStart, loginSuccess, loginFailure, simulateLocalhostLogin, User } from '../store/authSlice';
import { Button } from '../components/Button';
import { AppDispatch } from '../store/store';
import { api } from '../services/api';
import axios from "axios";
import { useAppSelector } from '../store/hooks';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
`;

const LoginPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { isAuthenticated, loading, error } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const hasNavigated = useRef(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      fetchUserInfo(accessToken).then((user) => {
        if (user) {
          dispatch(loginSuccess(user));
          navigate('/home');
        }
      });
    } else if (code) {
      handleLoginWithCode(code);
    } else if (isAuthenticated && !hasNavigated.current) {
      hasNavigated.current = true;
      navigate('/home');
    }
  }, [isAuthenticated, navigate]);

  const fetchUserInfo = async (access_token: string) => {
    const userRes = await axios.get('api/userinfo', {
      headers: { Authorization: `Bearer ${access_token}` },
    });

    if (userRes.status !== 200) {
      return null;
    }

    return userRes.data;
  }

  const handleLoginWithCode = async (code: string) => {
    dispatch(loginStart());
    try {
      const res = await api.get('auth/token', {
        params: { code },
      });
      const { access_token } = res.data;
      localStorage.setItem('access_token', access_token);

      const user = await fetchUserInfo(access_token);
      if (!user) {
        dispatch(loginFailure('Login failed'));
        return;
      }

      dispatch(loginSuccess(user));
      navigate('/home');
    } catch (error) {
      dispatch(loginFailure('Login failed'));
    }
  };


  const handleLoginSuccess = async (response: any) => {
    dispatch(loginStart());
    try {
      const { credential } = response;
      const res = await api.get('auth/callback', {
        params: { code: credential },
      });
      const { access_token } = res.data;
      localStorage.setItem('access_token', access_token);

      // Fetch user info from the backend or decode it from the token
      const userRes = await axios.get('api/userinfo', {
         headers: { Authorization: `Bearer ${access_token}` },
      });
      const user = userRes.data;
      dispatch(loginSuccess(user));
    } catch (error) {
      dispatch(loginFailure('Login failed'));
    }
  };

  const handleLoginFailure = () => {
    dispatch(loginFailure('Login failed'));
  };

  const handleLoginRedirect = () => {
    window.location.href = '/auth/login/google';
  };

  const handleSimulateLogin = () => {
    dispatch(simulateLocalhostLogin());
  };

  if (isAuthenticated && hasNavigated.current) {
    return null;
  }

  return (
    <Container>
      <h2>Login</h2>
      {window.location.hostname === 'localhost' ? (
        <Button onClick={handleSimulateLogin}>Simulate Localhost Login</Button>
      ) : (
        <Button onClick={handleLoginRedirect}>Login with Google</Button>
      )}
      {loading && <p>Loading...</p>}
      {error && <p className="error">{error}</p>}
    </Container>
  );
};

export default LoginPage;