import styled from 'styled-components';

const StyledButton = styled.button<{$fullWidth: boolean}>`
    background-color: #10a37f;
    color: white;
    border: none;
    padding: .5rem 1rem;
    cursor: pointer;
    border-radius: .5rem;
    box-sizing: border-box;
    width: ${({$fullWidth}) => $fullWidth ? '100%' : 'auto'};
  
    &:hover {
        background-color: #0e8a6a;
    }
`;

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    fullWidth?: boolean;
}

export const Button: React.FC<ButtonProps> = ({fullWidth = false, ...rest }) => {
    return <StyledButton $fullWidth={fullWidth} {...rest} />
}