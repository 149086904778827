import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles.css';
import App from './App';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Provider } from 'react-redux';
import store from './store/store';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container!);

// Use REACT_APP prefix for environment variables in Create React App
const GOOGLE_CLIENT_ID = "249198693469-ifsi462am2fpv69rs3bid9j8h8kc9ip4.apps.googleusercontent.com";

// if (!GOOGLE_CLIENT_ID) {
//   throw new Error("Missing Google Client ID environment variable");
// }

root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <Provider store={store}>
        <App />
      </Provider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);