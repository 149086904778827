import React from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { AppDispatch } from '../store/store';
import { createThread } from '../store/chatSlice';
import { Button } from './Button';
import { useAppSelector } from '../store/hooks';

const StyledContainer = styled.div`
  width: 15rem;
  background-color: #2e2f38;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  flex-grow: 1;
  overflow-y: auto;
`;

const StyledListItem = styled.li`
  &:hover {
    background-color: #40414f;
  }
`;

const StyledHeading = styled.h3`
  color: #d1d5db;
`;

const StyledLink = styled(NavLink)`
  text-decoration: none;
  color: inherit;
  display: block;
  padding: 0.5rem;
  cursor: pointer;
  border-bottom: 1px solid #40414f;

  &.active {
    background-color: #343541;
  }

  &:hover {
    color: #a8a8a8;
  }
`;

const Sidebar: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const threads = useAppSelector((state) => state.chat.threads);

  const startNewChat = async () => {
    try {
      const resultAction = await dispatch(createThread()).unwrap();
      navigate(`/home/chat/${resultAction.id}`);
    } catch (error) {
      console.error('Failed to create a new chat thread:', error);
    }
  };

  return (
    <StyledContainer>
      <div>
        <Button fullWidth onClick={startNewChat}>New Chat</Button>
        <StyledHeading>Previous Chats</StyledHeading>
        <StyledList>
          {Object.keys(threads).map((threadId) => (
            <StyledListItem key={threadId}>
              <StyledLink to={`/home/chat/${threadId}`}>
                {threads[threadId]}
              </StyledLink>
            </StyledListItem>
          ))}
        </StyledList>
      </div>
    </StyledContainer>
  );
};

export default Sidebar;