import styled, { css } from 'styled-components';
import { useEffect, useRef } from 'react';
import Markdown from 'react-markdown';
import rehypeExternalLinks from 'rehype-external-links'

import { RootState } from '../store/store';
import { useAppSelector } from '../store/hooks';

const StyledContainer = styled.div`
  overflow-y: scroll;
  height: 100%;
  padding: 1rem;
`;

const messageStyles = css`
  padding: .5rem 1rem;
  margin-bottom: .5rem;
  border-radius: 0.5rem;
  color: #d1d5db;
  max-width: 80%;
  width: fit-content;

  a {
    color: #dadada;
  }

  p {
    margin: .5rem 0;
  }
`;

const StyledUserMessage = styled.div`
  ${messageStyles}
  background-color: #3b3f45;
  margin-left: auto;
`;

const StyledSystemMessage = styled.div`
  ${messageStyles}
  background-color: #40414f;
  margin-right: auto;
`;

const StyledAssistantMessage = styled.div`
  ${messageStyles}
  background-color: #2b2e3a;
  margin-right: auto;
`;

const StyledSourceContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding-top: .5rem;

    a,
    span {
        display: inline-block;
        background-color: #393b4b;
        padding: .5rem;
        border-radius: .5rem;
    }
`;

const StyledHR = styled.hr`
    margin: 1rem 0;
`;

export const ChatHistory: React.FC = () => {
  const messages = useAppSelector((state: RootState) => state.chat.messages);
  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    containerRef.current?.scrollTo({ top: containerRef.current.scrollHeight });
  }, [messages.length]);

  return (
    <StyledContainer ref={containerRef}>
      {messages.map((message, index) => {
        if (message.role === 'user') {
          return (
            <StyledUserMessage key={index}>
              <Markdown rehypePlugins={[[rehypeExternalLinks, {target: '_blank'}]]}>{message.content}</Markdown>
            </StyledUserMessage>
          );
        } else if (message.role === 'assistant') {
          return (
            <StyledAssistantMessage key={index}>
            {!!message.sources?.length && (
                <>
                    <StyledSourceContainer>
                        {message.sources.map(source => (
                            source.url ? <a href={source.url} key={source.id} target='_blank'>{source.name}</a> : <span key={source.id}>{source.name}</span>
                        ))}
                    </StyledSourceContainer>
                    <StyledHR/>
                </>
            )}
            <div>
                <Markdown rehypePlugins={[[rehypeExternalLinks, {target: '_blank'}]]}>{message.content}</Markdown>
            </div>
        </StyledAssistantMessage>
          );
        } else {
          return (
            <StyledSystemMessage key={index}>
              <Markdown rehypePlugins={[[rehypeExternalLinks, {target: '_blank'}]]}>{message.content}</Markdown>
            </StyledSystemMessage>
          );
        }
      })}
    </StyledContainer>
  );
};