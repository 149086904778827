import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../services/api';

export interface Source {
  id: string;
  name: string;
  url: string | null;
}

export interface Message {
  role: 'system' | 'user' | 'assistant';
  content: string;
  timestamp?: string;
  assistant?: string;
  sources?: Source[];
}

export interface Thread {
  id: string;
  messages: Message[];
}

interface ChatState {
  threads: Record<string, string>;
  currentThreadId: string | null;
  messages: Message[];
  loading: boolean;
  error: string | null;
  assistants: any[]; // Add a property to store the assistants
}

const initialState: ChatState = {
  threads: getThreadsFromLocalStorage(),
  currentThreadId: null,
  messages: [],
  loading: false,
  error: null,
  assistants: [] // Initialize the assistants property
};

function saveThreadToLocalStorage(thread: Thread) {
    const threads = getThreadsFromLocalStorage();

    if (!threads[thread.id] || threads[thread.id] === 'Empty chat') {
        threads[thread.id] = !!thread.messages.length ? thread.messages[0].content : 'Empty chat';
        localStorage.setItem('threads', JSON.stringify(threads));
    }
}

function getThreadsFromLocalStorage() {
    const threads = localStorage.getItem('threads') || '{}';
    const parsedThreads: Record<string, string> = JSON.parse(threads);

    return parsedThreads;
}

// Async thunk to create a new thread
export const createThread = createAsyncThunk(
  'chat/createThread',
  async (_, thunkAPI) => {
    try {
      const accessToken = localStorage.getItem('access_token');
      const response = await api.post(
        `api/threads`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );
      return response.data; // Return the full response data
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || 'Something went wrong');
    }
  }
);

// Async thunk to get assistants
export const getAssistants = createAsyncThunk(
  'chat/getAssistants',
  async (_, thunkAPI) => {
    try {
      const accessToken = localStorage.getItem('access_token');
      const response = await api.get(
        `api/assistants`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );
      return response.data; // Return the full response data
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || 'Something went wrong');
    }
  }
);
export const fetchThread = createAsyncThunk(
    'chat/fetchThread',
    async (threadId: string, thunkAPI) => {
      try {
        const accessToken = localStorage.getItem('access_token');
        const response = await api.get(
            `api/threads/${threadId}`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            }
        );
        return response.data; // Return the full response data
      } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response?.data || 'Something went wrong');
      }
    }
  );



// Async thunk to send message to the API
export const postMessage = createAsyncThunk(
  'chat/postMessage',
  async (message: Message, thunkAPI) => {
    const state = thunkAPI.getState() as { chat: ChatState };
    const { currentThreadId } = state.chat;

    const accessToken = localStorage.getItem('access_token');
    try {
      const response = await api.post(
        `api/threads/${currentThreadId}/messages`,
        {
          assistant: message.assistant,
          content: message.content
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      return response.data; // Return the full response data
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || 'Something went wrong');
    }
  }
);

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    sendMessage: (state, action: PayloadAction<Message>) => {
      state.messages.push(action.payload);
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(createThread.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createThread.fulfilled, (state, action: PayloadAction<Thread>) => {
        state.loading = false;
        const newThread = action.payload;
        state.currentThreadId = newThread.id;
        state.threads = {...state.threads, [newThread.id]: !!newThread.messages.length ? newThread.messages[0].content : 'Empty chat'}
        saveThreadToLocalStorage(action.payload);
        state.messages = newThread.messages;
      })
      .addCase(createThread.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(postMessage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(postMessage.fulfilled, (state, action: PayloadAction<Message>) => {
        state.loading = false;
        state.messages.push(action.payload);
      })
      .addCase(postMessage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(getAssistants.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAssistants.fulfilled, (state, action: PayloadAction<any[]>) => {
        state.loading = false;
        state.assistants = action.payload; // Store the assistants data
      })
      .addCase(getAssistants.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(fetchThread.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchThread.fulfilled, (state, action: PayloadAction<Thread>) => {
        state.loading = false;
        state.currentThreadId = action.payload.id;
        state.threads = {...state.threads, [action.payload.id]: !!action.payload.messages.length ? action.payload.messages[0].content : 'Empty chat'};
        saveThreadToLocalStorage(action.payload);
        state.messages = action.payload.messages;
      })
      .addCase(fetchThread.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { sendMessage } = chatSlice.actions;
export default chatSlice.reducer;