import React, { useEffect } from 'react';
import styled from 'styled-components';
import { RootState } from '../store/store';
import { fetchThread } from '../store/chatSlice';
import { ChatHistory } from '../components/ChatHistory'; // Ensure the correct import path
import { ChatInput } from '../components/ChatInput'; // Ensure the correct import path
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { useParams } from 'react-router-dom';

const Container = styled.div`
  border-radius: .5rem 0 0 0;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  flex-direction: column;
  background-color: #343541;
`;

export const ChatPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const {id}  = useParams();
  const { error } = useAppSelector((state) => state.chat);

  useEffect(() => {
    if (id) {
      dispatch(fetchThread(id));
    }
  }, [dispatch, id]);

  return (
    <Container>
      <ChatHistory />
      <ChatInput />
      {error && <div>Error: {JSON.stringify(error)}</div>}
    </Container>
  );
};