import styled from 'styled-components'

const StyledSpinner = styled.div`
    color: #888;
    box-sizing: border-box;
    display: inline-block;
    position: relative;
    width: 2rem;
    height: 2rem;

  div {
    box-sizing: border-box;
    position: absolute;
    top: .75rem;
    width: .5rem;
    height: .5rem;
    border-radius: 50%;
    background: currentColor;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  div:nth-child(1) {
    left: .5rem;
    animation: ellipsis 0.6s infinite;
  }
  div:nth-child(2) {
    left: .5rem;
    animation: lds-ellipsis2 0.6s infinite;
  }
  div:nth-child(3) {
    left: 2rem;
    animation: lds-ellipsis2 0.6s infinite;
  }
  div:nth-child(4) {
    left: 3.5rem;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes ellipsis {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(1.5rem, 0);
    }
  }

`
export const Spinner: React.FC<{className?: string}> = ({className}) => (
    <div className={className}><StyledSpinner><div></div><div></div><div></div><div></div></StyledSpinner></div>
);
